import React, { FC, useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  CardMedia,
} from "@mui/material";
import classNames from "classnames/bind";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./reportDetails.module.css";
import { PathologyConclusion, ReportDicom } from "../../api/generated_api";

const cx = classNames.bind(styles);

export const PathologyRow = ({ data, dicoms }: { data: PathologyConclusion; dicoms: ReportDicom[] }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow
        key={data.pathology?.value}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => {
          return setOpen((prevState) => !prevState);
        }}
        className={cx({ tableRow: true })}
      >
        <TableCell className={styles.tableCell} colSpan={0.5}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={"pathology_feature_name"} align="left" className={styles.tableCell}>
          {data?.pathology?.feature?.value || "-"}
        </TableCell>
        <TableCell id={"pathology_name"} align="left" className={styles.tableCell}>
          {data?.pathology?.value || "-"}
        </TableCell>
        <TableCell align="right" className={styles.tableCell}>
          <Box id={"pathology_score"} sx={{ width: "100%" }}>
            {data.pathology_output && data.pathology?.value ? ((data.pathology_output) ? data.pathology_output : `${data.pathology?.feature?.value} ${data.pathology?.value} ${data.stratification?.value}`) : '-'}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "black" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {data.dicoms.map((dicom) => {
                  const dicom_match = dicoms.find((element) => {
                    return element.uuid === dicom.uuid;
                  });
                  if (dicom_match === undefined || dicom_match.media === undefined) {
                    return null;
                  }

                  return (
                    <Box key={dicom.uuid} sx={{ marginBottom: 5 }}>
                      <Typography align={"center"}>{dicom_match && dicom_match.name}</Typography>
                      <CardMedia key={dicom_match.uuid} component={"video"} autoPlay loop src={dicom_match.media} />
                    </Box>
                  );
                })}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
